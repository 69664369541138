<template>
    <el-form ref="data" class="case-view">
        <div v-for="(config, i) in configs" :key="i" class="inner">
            <div class="config">
                <component
                    v-for="(render, j) in config"
                    :key="j"
                    :value="render.fieldName ? data[render.fieldName] : data"
                    :is="render.component"
                    :label="render.label"
                    :config="render"
                    mode="VIEW"
                >
                </component>
            </div>
        </div>
    </el-form>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapState({
            configs: state => state.config.view
        })
    }
};
</script>

<style scoped lang="scss">
.case-view {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
    box-sizing: border-box;
    > :first-child {
        margin-top: 0 !important;
    }
    > :last-child {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .config {
        width: 100%;
        padding: 0 10px;
        margin: 0 auto;
        box-sizing: border-box;
        @media screen and (min-width: 640px) {
            padding: 0 30px;
        }
    }
    .inner {
        .config > :first-child {
            margin-top: 0 !important;
        }
        .config > :last-child {
            margin-bottom: 0 !important;
        }
        .config > .overview-data {
            margin-top: -15px !important;
            margin-bottom: -15px !important;
        }
        width: 100%;
        margin: 40px 0;
        padding: 30px 0;
        border-radius: 14px;
        box-sizing: border-box;
    }
}
</style>
